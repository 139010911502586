/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React, { useContext, useEffect, useRef } from 'react';
import { useDocumentEventListener, useTimeout } from '@exo/frontend-common-hooks';
import { gql, useMutation, useQuery } from '@apollo/client';
import { dispatchEXOCustomEvent } from '@exo/frontend-common-utils';
import { useLocation } from "react-router-dom";
import { useSessionContext } from '@exo/frontend-common-session-context';

declare global {
  interface EXOSession {
    token?: string;
    tokenExpiryTime?: number;
    type?: UserType;

    // TODO: Make this optional
    roles: string[];
    username?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
  }

  interface DocumentEventMap {
    'exo.tokenError': CustomEvent<{}>;
    'exo.tokenWillExpire': CustomEvent<{
      expiresIn: number;
    }>;
    'exo.tokenChange': CustomEvent<{
      newToken: string | undefined;
      oldToken: string | undefined;
      isRefresh: boolean;
    }>;
  }
}

export const parseTokenExpiryTime = (expiresAt: string | undefined) => {
  if (expiresAt === undefined) {
    // TODO: Make this mandatory in the adapters instead
    console.warn(`No token expiry time provided`);
    return undefined;
  }
  return new Date(expiresAt).getTime()
}
export const TokenContext = React.createContext<TokenContextType | undefined>(undefined);

export type TokenContextType = {
  ensureValidToken: () => Promise<string>;
  getToken: () => string | undefined;
};

type AutAuthenticationResult = { token: string; expiresAt: string };
type AuthGuestResponse = { authGuest: AutAuthenticationResult };
type AuthRefreshResponse = { authRefresh: AutAuthenticationResult };

type MeResponse = {
  me: {
    email: string;
    firstName: string;
    lastName: string;
    roles: string[];
  };
};
type UserType = 'NONE' | 'GUEST' | 'USER' | string;

export const useTokenContext = () => {
  const session = useContext(TokenContext);
  console.assert(!!session, 'No token context found');
  return session!;
};

const GQL_GET_ME = gql`
  query Me {
    me {
      id
      email
      firstName
      lastName
      roles
    }
  }
`;

const GQL_AUTH = gql`
  mutation Auth {
    authGuest {
      token
      expiresAt
    }
  }
`;

const GQL_AUTH_REFRESH = gql`
  mutation AuthRefresh {
    authRefresh {
      token
      expiresAt
    }
  }
`;

// TODO: Make these two constants configurable through config (application)

// Reresh 5 minutes before token expiration
const OFFSET = Number(process.env.TOKEN_CONTEXT_OFFSET ?? 5 * 60 * 1000);

// Use 10 minutes as max idle time
const MAX_IDLE = Number(process.env.TOKEN_CONTEXT_MAX_IDLE ?? 0);

export const TokenContextProvider = ({
  children,
  isGuest = false
}: Props) => {
  const session = useSessionContext();
  const knownTokenRef = useRef(session.token);

  const lastRouteChange = useRef(new Date().getTime());
  const lastRoute = useRef('');
  const location = useLocation();

  // Keep track of interactions with the site
  // Route changes may not be perfect, but should be good enough
  if (lastRoute.current !== location.pathname) {
    lastRouteChange.current = new Date().getTime()
    lastRoute.current = location.pathname;
  }

  const [guesttoken] = useMutation<AuthGuestResponse>(GQL_AUTH);
  const [refresh] = useMutation<AuthRefreshResponse>(GQL_AUTH_REFRESH);

  // TODO: Is this needed, isn't this always handled by the login mechanism?
  const { data } = useQuery<MeResponse>(
    GQL_GET_ME,
    {
      onCompleted: () => {
        if (!data) return;

        session.update({
          roles: data?.me?.roles ?? [],
          email: data?.me?.email,
          firstName: data?.me?.firstName,
          lastName: data?.me?.lastName
        })
      },

      // Only fetch if no TYPE or no roles
      skip: !session.type || session.type === 'NONE' || session?.roles?.length > 0
    }
  );

  const time = session?.tokenExpiryTime ? Math.max(0, session?.tokenExpiryTime - new Date().getTime() - OFFSET) : 0;
  useTimeout(async () => {
    const now = new Date().getTime();

    if (now >= (session?.tokenExpiryTime ?? now)) return;
    if (typeof window === 'undefined') return;

    if (now - lastRouteChange.current > MAX_IDLE) {
      dispatchEXOCustomEvent('exo.tokenWillExpire', {
        detail: { expiresIn: (session?.tokenExpiryTime ?? now) - now }
      });
    } else {
      const { token, expiresAt } = (await refresh()).data!.authRefresh;

      session.update({ token, tokenExpiryTime: parseTokenExpiryTime(expiresAt) });

      dispatchEXOCustomEvent('exo.tokenChange', {
        detail: {
          newToken: token,
          oldToken: knownTokenRef.current,
          isRefresh: true
        }
      });
      knownTokenRef.current = token;
    }
  }, time);

  useDocumentEventListener('exo.tokenError', () => {
    session.replace({ roles: [] });

    dispatchEXOCustomEvent('exo.tokenChange', {
      detail: {
        newToken: undefined,
        oldToken: knownTokenRef.current,
        isRefresh: false
      }
    });
    knownTokenRef.current = undefined;
  })

  // This is to handle any updates to the session from other parts of the application
  // e.g. login etc
  useEffect(() => {
    if (knownTokenRef.current !== session.token) {
      dispatchEXOCustomEvent('exo.tokenChange', {
        detail: {
          newToken: session.token,
          oldToken: knownTokenRef.current,
          isRefresh: false
        }
      });
      knownTokenRef.current = session.token;
    }
  }, [session.token])

  // Ensure token context is not nested
  const tokenContext = useContext(TokenContext);
  if (tokenContext) {
    return children;
  }


  const acquireGuesttoken = async () => {
    const { token, expiresAt } = (await guesttoken()).data!.authGuest;
    session.update({
      type: 'GUEST' as UserType,
      token,
      tokenExpiryTime: parseTokenExpiryTime(expiresAt),
      roles: ['guest']
    });
    return token;
  };

  const value = {
    ensureValidToken: () => {
      if (session?.token) return Promise.resolve(session?.token);
      if (isGuest) return acquireGuesttoken();
      throw new Error('No token can be acquired')
    },
    getToken: () => session?.token
  };

  return <TokenContext.Provider value={value}>{children}</TokenContext.Provider>;
};

type Props = {
  isGuest?: boolean;
  children: any;
};


